import React from 'react'
import styles from '../Header.module.css'
import Names from './Names'
import Rights from './Rights'

const Home = () => {
  return (
    <div>
        <Rights/>
        <Names/>
    </div>
  )
}

export default Home