import React from 'react'
import Aid from './Aid'
import Rights from './Rights'

const Give = () => {
  return (
    <div>
        <Aid/>
    </div>
  )
}

export default Give