import React from 'react'
import styles from '../Header.module.css'
import Buttons from './Buttons'

const Rights = () => {
  return (
    <div>
        <div className={styles.subTitle}>
            <div>
              <div className={styles.miniTitle}>בחרו דף גמרא ללימוד יומי</div>
              מסיימים את הש״ס בכל יום<br/>
              לשמירה על חיילי צה״ל<br/>
              לרפואת הפצועים<br/>
              לפדיון השבויים
            </div>
        </div>
        <div className={styles.buttons}>
          <Buttons/>
        </div>
    </div>
  )
}

export default Rights