import React from 'react'
import styles from '../Header.module.css'

const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.divTitle}>
        <div className={styles.title}>
           חרבות של מעשים טובים
        </div>
      </div>
    </div>
  )
}

export default Header